import { gql } from '@apollo/client';
import {
  ADDED_SERVICE_FRAGMENT,
  AIRPORT_FRAGMENT,
  BAGGAGE_ALLOWANCE_FRAGMENT,
  CITY_FRAGMENT,
  ITINERARY_FRAGMENT,
  TICKETING_DETAILS_FRAGMENT,
} from 'api/fragments';
import { Currency, TicketClassEnum } from 'enum/enum';
import { ItineraryRequestApiModel } from 'models/itinerary.model';
import { JourneyOfferModel } from 'models/journeyOption.model';

export interface JOURNEY_OFFERS_data {
  journeyOffers: JourneyOfferModel[];
}

export interface JOURNEY_OFFERS_vars {
  adults: number;
  cabin: TicketClassEnum;
  children: number;
  currency: Currency;
  includeAeroCRS: boolean;
  includeAmadeus: boolean;
  infants: number;
  requestedItineraries: ItineraryRequestApiModel[];
}

const ERROR_FRAGMENT = gql`
  fragment ErrorFragment on SearchServiceNotAvailable {
    service
    description
  }
`;

export const JOURNEY_OFFER_FRAGMENT = gql`
  fragment JourneyOfferFragment on JourneyOffer {
    id
    source
    priced
    farePerAdult
    farePerChild
    farePerInfant
    brandedFare
    majorityCabin
    dateChange
    arrivalDate
    departureDate
    priceQuote {
      ...PriceQuoteFragment
    }
    originAirport {
      ...AirportFragment
    }
    destinationAirport {
      ...AirportFragment
    }
    originCity {
      ...CityFragment
    }
    destinationCity {
      ...CityFragment
    }
    itineraries {
      ...ItineraryFragment
    }
    baggageAllowance {
      ...BaggageAllowanceFragment
    }
    ticketingDetails {
      ...TicketingDetailsFragment
    }
    addedServices {
      ...AddedServiceFragment
    }
  }
  ${AIRPORT_FRAGMENT}
  ${CITY_FRAGMENT}
  ${ITINERARY_FRAGMENT}
  ${BAGGAGE_ALLOWANCE_FRAGMENT}
  ${TICKETING_DETAILS_FRAGMENT}
  ${ADDED_SERVICE_FRAGMENT}
`;

export const JOURNEY_OFFERS = gql`
  query JourneyOffers(
    $adults: Int
    $cabin: CabinType
    $children: Int
    $currency: CURRENCYCHOICES!
    $includeAeroCRS: Boolean
    $includeAmadeus: Boolean!
    $infants: Int
    $requestedItineraries: [ItineraryRequest!]!
  ) {
    journeyOffers(
      adults: $adults
      cabin: $cabin
      children: $children
      currency: $currency
      includeAeroCRS: $includeAeroCRS
      includeAmadeus: $includeAmadeus
      infants: $infants
      requestedItineraries: $requestedItineraries
    ) {
      ...JourneyOfferFragment
      ...ErrorFragment
    }
  }
  ${JOURNEY_OFFER_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

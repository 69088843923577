import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  isAmadeus: boolean;
  isAerocrs: boolean;
  isDebug: boolean;
  fuzzyLevel: number;
  isProductionMode: boolean;
  enableTTL: boolean;
  customGraphqlUrl: string | null;
}

const initialState: InitialState = {
  isAmadeus: true,
  isDebug: JSON.parse(process.env.REACT_APP_DEBUG_MODE ?? 'false'),
  isAerocrs: true,
  fuzzyLevel: 0.25,
  isProductionMode: false,
  enableTTL: false,
  customGraphqlUrl: null,
};

export const debuggerOptionsSlice = createSlice({
  name: 'optionsSlice',
  initialState,
  reducers: {
    setAmadeusChecked(state, action: PayloadAction<boolean>) {
      state.isAmadeus = action.payload;
    },
    setAerocrsChecked(state, action: PayloadAction<boolean>) {
      state.isAerocrs = action.payload;
    },
    setDebuggerMode(state, action: PayloadAction<boolean>) {
      state.isDebug = action.payload;
    },
    setFuzzyLevel(state, action: PayloadAction<number>) {
      state.fuzzyLevel = action.payload;
    },
    setProductionMode(state, action: PayloadAction<boolean>) {
      state.isProductionMode = action.payload;
    },
    setEnableTTLModal(state, action: PayloadAction<boolean>) {
      state.enableTTL = action.payload;
    },
    setCustomGraphqlUrl(state, action: PayloadAction<string | null>) {
      state.customGraphqlUrl = action.payload;
    },
  },
});
export const { setAmadeusChecked, setDebuggerMode, setProductionMode, setAerocrsChecked, setEnableTTLModal, setFuzzyLevel, setCustomGraphqlUrl } =
  debuggerOptionsSlice.actions;

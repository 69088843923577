import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import { toast } from 'react-toastify';

import { REACT_APP_AMPLITUDE_API_KEY } from 'constants/amplitude';
import { REACT_APP_BASE_URL_GRAPHQL, REACT_APP_BASE_URL_GRAPHQL_PROD } from 'constants/baseUrl';
import { REACT_APP_GOOGLE_ANALYTICS_ID } from 'constants/googleAnalytics';
import { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_ENVIRONMENT } from 'constants/sentry';

import { store as reduxStore } from 'redux/store';
import { getSessionId } from 'utils/sessionId';

export const runAppConfig = async () => {
  const sessionId = getSessionId();

  ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
  const browserHistory = createBrowserHistory();
  browserHistory.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
  });

  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: REACT_APP_SENTRY_ENVIRONMENT,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
  });

  // setting an identifier for the user during a session
  Sentry.setUser({ id: sessionId as string });
  ReactGA.set({ userId: sessionId });
  amplitude.setUserId(sessionId as string);
  const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 1 });
  await amplitude.add(sessionReplayTracking).promise;
  amplitude.init(REACT_APP_AMPLITUDE_API_KEY);
};

export const runApolloClientConfig = (isProduction: boolean, token: string | null, refCode: string | null) => {
  const sessionId = getSessionId();
  const customGraphqlUrl = reduxStore.getState().debugger.customGraphqlUrl;

  const link = createHttpLink({
    uri: customGraphqlUrl || (isProduction ? REACT_APP_BASE_URL_GRAPHQL_PROD : REACT_APP_BASE_URL_GRAPHQL),
    credentials: 'include',
  });

  const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (networkError) {
      toast.error('Please check your connection - contact support@fasax.com if you need further assistance');
    } else if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        toast.error(message);
      });
    }
  });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        'client-session-id': sessionId,
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        ...(refCode && { 'fasax-referrer-code': refCode }),
      },
    };
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: errorLink.concat(authLink.concat(link)),
    ...(token && { headers: { authorization: token } }),
  });
};

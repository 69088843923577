import { gql } from '@apollo/client';

export const MONITORING_QUERY = gql`
  query MonitoringQuery {
    monitoring
  }
`;

export interface MonitoringQueryResponse {
  monitoring: string;
}

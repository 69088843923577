import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { Alert, Button, InputLabel, ListItemText, Slider, TextField, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import { REACT_APP_BASE_URL_GRAPHQL, REACT_APP_BASE_URL_GRAPHQL_PROD } from 'constants/baseUrl';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MONITORING_QUERY, MonitoringQueryResponse } from 'api/queries/monitoring';
import { useAppSelector } from 'hooks/useAppSelector';
import { useDebounce } from 'hooks/useDebounce';
import {
  setAerocrsChecked,
  setAmadeusChecked,
  setCustomGraphqlUrl,
  setEnableTTLModal,
  setFuzzyLevel,
  setProductionMode,
} from 'redux/slices/debuggerOptions/debuggerOptions-slice';
import { setAmadeus } from 'redux/slices/search/search-slice';
import { ListHeaderItem } from '../Drawer/styles';
import { Toggle } from '../kit/Toggle';
import { SliderContainer, VersionContainer, VersionItem } from './styles';

export const Debugger = () => {
  const { isAmadeus, isAerocrs, isDebug, fuzzyLevel, isProductionMode, enableTTL, customGraphqlUrl } = useAppSelector((state) => state.debugger);
  const dispatch = useDispatch();
  const [date, setDate] = useState<string>('');
  const [commit, setCommit] = useState<string>('');
  const [fuzzyLevelValue, setFuzzyLevelValue] = useState<number>(fuzzyLevel);
  const [tempGraphqlUrl, setTempGraphqlUrl] = useState<string>(customGraphqlUrl || '');
  const [isValidating, setIsValidating] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);

  const debouncedValue = useDebounce(fuzzyLevelValue, 500);
  const valuetext = (value: number) => `${value}`;

  const currentGraphqlUrl = customGraphqlUrl || (isProductionMode ? REACT_APP_BASE_URL_GRAPHQL_PROD : REACT_APP_BASE_URL_GRAPHQL);

  const handlerChangeAmadeus = (value: boolean) => {
    dispatch(setAmadeusChecked(value));
    dispatch(setAmadeus(value));
  };

  const handlerChangeAerocrs = (value: boolean) => {
    dispatch(setAerocrsChecked(value));
  };

  const handleChangeProductionMode = (value: boolean) => {
    dispatch(setProductionMode(value));
  };

  const handleChangeTTLModal = (value: boolean) => {
    dispatch(setEnableTTLModal(value));
  };

  const handleTempGraphqlUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempGraphqlUrl(event.target.value);
    setValidationError(null);
  };

  const validateAndSaveGraphqlUrl = async () => {
    if (!tempGraphqlUrl) {
      dispatch(setCustomGraphqlUrl(null));
      return;
    }

    setIsValidating(true);
    setValidationError(null);
    try {
      const testClient = new ApolloClient({
        link: createHttpLink({
          uri: tempGraphqlUrl,
          credentials: 'include',
        }),
        cache: new InMemoryCache(),
      });

      const result = await testClient.query<MonitoringQueryResponse>({
        query: MONITORING_QUERY,
      });

      if (result.data.monitoring === 'OK') {
        dispatch(setCustomGraphqlUrl(tempGraphqlUrl));
      } else {
        setTempGraphqlUrl(customGraphqlUrl || '');
        setValidationError('GraphQL endpoint returned an invalid response');
      }
    } catch (error) {
      console.error('Failed to validate GraphQL URL:', error);
      setTempGraphqlUrl(customGraphqlUrl || '');
      setValidationError(error instanceof Error ? error.message : 'Failed to validate GraphQL URL');
    } finally {
      setIsValidating(false);
    }
  };

  const generatedFuzzyMarks = useMemo(() => {
    const marks = [];
    for (let i = 0; i <= 10; i++) {
      const currValue = +(i / 10).toFixed(2);
      marks.push({ label: `${currValue * 10}`, value: currValue });
    }
    return marks;
  }, []);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    const newValueAsNumber = newValue as number;
    setFuzzyLevelValue(newValueAsNumber);
  };

  useEffect(() => {
    dispatch(setFuzzyLevel(debouncedValue));
  }, [debouncedValue]);

  useEffect(() => {
    const buildVersion = document.querySelector('meta[build-version]');
    const commitVersion = document.querySelector('meta[name="ui-version"]');
    if (buildVersion) {
      setDate(buildVersion.getAttribute('build-version') || '');
    }
    if (commitVersion) {
      setCommit(commitVersion.getAttribute('content') || '');
    }
  }, []);

  return (
    <>
      {isDebug ? (
        <>
          <ListHeaderItem>
            <ListItemText primary="Debug" />
          </ListHeaderItem>
          <ListItem>
            <Toggle checked={isAmadeus} label="Amadeus" onChangeCallBack={handlerChangeAmadeus} />
          </ListItem>
          <ListItem>
            <Toggle checked={isAerocrs} label="Aerocrs" onChangeCallBack={handlerChangeAerocrs} />
          </ListItem>
          <ListItem>
            <Toggle checked={isProductionMode} label="Production mode" onChangeCallBack={handleChangeProductionMode} />
          </ListItem>
          <ListItem>
            <Toggle checked={enableTTL} label="Enable TTL Modal" onChangeCallBack={handleChangeTTLModal} />
          </ListItem>
          <ListItem sx={{ flexDirection: 'column', alignItems: 'stretch' }}>
            <Typography variant="caption" color="textSecondary" sx={{ mb: 1 }}>
              Current GraphQL URL: {currentGraphqlUrl}
            </Typography>
            <TextField
              fullWidth
              label="Custom GraphQL URL"
              variant="outlined"
              value={tempGraphqlUrl}
              onChange={handleTempGraphqlUrlChange}
              placeholder={REACT_APP_BASE_URL_GRAPHQL}
              size="small"
              sx={{ mb: 1 }}
              error={!!validationError}
            />
            {validationError && (
              <Alert severity="error" sx={{ mb: 1 }}>
                {validationError}
              </Alert>
            )}
            <Button variant="contained" onClick={validateAndSaveGraphqlUrl} disabled={isValidating} fullWidth size="small">
              {isValidating ? 'Validating...' : 'Validate & Save URL'}
            </Button>
          </ListItem>
          <SliderContainer>
            <InputLabel>Fuzzy level:</InputLabel>
            <Slider
              max={1}
              getAriaValueText={valuetext}
              step={0.05}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => value * 10}
              marks={generatedFuzzyMarks}
              value={fuzzyLevelValue}
              onChange={handleSliderChange}
            />
          </SliderContainer>

          <ListItem>
            <VersionContainer>
              <VersionItem>
                <strong>Date build: </strong>
                <div>{date}</div>
              </VersionItem>
              <VersionItem>
                <strong>Last commit: </strong>
                <div>{commit}</div>
              </VersionItem>
            </VersionContainer>
          </ListItem>
        </>
      ) : null}
    </>
  );
};

import { ApolloProvider } from '@apollo/client';
import { useMemo } from 'react';
import { PersistGate } from 'redux-persist/integration/react';

import { runApolloClientConfig } from 'config/config';
import { useAppSelector } from 'hooks/useAppSelector';
import { App } from 'pages/App';
import { persistor } from 'redux/store';

export const AppWithApollo = () => {
  const { isProductionMode, customGraphqlUrl } = useAppSelector((state) => state.debugger);
  const token = useAppSelector((state) => state.authSlice.token);
  const refCode = useAppSelector((state) => state.meta.refCode);

  const client = useMemo(() => {
    return runApolloClientConfig(isProductionMode, token, refCode);
  }, [isProductionMode, token, refCode, customGraphqlUrl]);

  return (
    <ApolloProvider client={client}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </ApolloProvider>
  );
};
